import $ from 'jquery';

const settings = {
  errorElement: 'span',
  errorPlacement(error, element) {
    error.addClass('invalid-feedback');
    element.closest('.form-group, .file-wrapper').append(error);
  },
  highlight(element) {
    $(element).addClass('is-invalid');
  },
  unhighlight(element) {
    $(element).removeClass('is-invalid');
  },
};

const rules = {
  company: {
    checkurl: true,
  },
  phone: {
    required: true,
    digits: true,
  },
  email: {
    email: true,
  },
  file: {
    extension: 'pdf|doc|docx',
    filesize: 5000,
  },
};

function extraValidators(fields) {
  // Filesize
  $.validator.addMethod('filesize', function filesize(value, element, param) {
    return this.optional(element) || ((element.files[0].size / 1024) <= param);
  }, fields.file.size);

  // Check URL
  $.validator.addMethod('checkurl', function checkurl(value, element) {
    // eslint-disable-next-line
    return this.optional(element) || new RegExp('^(http:\/\/www|https:\/\/www|https\://|http\://)?[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(/\S*)?$').test(value);
  }, fields.company.checkUrl);
}

function messages(i18next) {
  const info = {
    name: {
      required: i18next.t('globals.demo.validations.name.required'),
    },
    company: {
      required: i18next.t('globals.demo.validations.company.required'),
      checkurl: i18next.t('globals.demo.validations.company.checkurl'),
    },
    job_position: {
      required: i18next.t('globals.demo.validations.job_position.required'),
    },
    phone: {
      required: i18next.t('globals.demo.validations.phone.required'),
      digits: i18next.t('globals.demo.validations.phone.digits'),
    },
    email: {
      required: i18next.t('globals.demo.validations.email.required'),
      email: i18next.t('globals.demo.validations.email.email'),
    },
    message: {
      required: i18next.t('globals.demo.validations.message.required'),
    },
    file: {
      required: i18next.t('globals.demo.validations.file.required'),
      extension: i18next.t('globals.demo.validations.file.extension'),
      filesize: i18next.t('globals.demo.validations.file.filesize'),
    },
  };

  extraValidators(info);

  return info;
}

export default function (i18next) {
  return {
    settings,
    messages: messages(i18next),
    rules,
  };
}
