import $ from 'jquery';
import i18next from 'i18next';

function goToSection(section, offset = 60, time = 600) {
  $('html, body').bind('scroll mousedown DOMMouseScroll mousewheel', () => $('html, body').stop());

  $('html:not(:animated), body:not(:animated)').stop().animate({
    scrollTop: section.offset().top - offset,
  }, time, () => $('html, body').unbind('scroll mousedown DOMMouseScroll mousewheel'));
}

function trackEventGA(category, action, label) {
  if ('ga' in window) {
    const tracker = ga.getAll()[0];

    if (tracker) tracker.send('event', category, action, label);
  }
}

function submitHandler(form) {
  const data = new FormData(form);

  const btnText = $(form).find('.btn').text();
  $(form).find('.btn').attr('disabled', true).html(`
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span>${i18next.t('globals.sending')}</span>
  `);
  $(form).find('.alert').addClass('d-none').removeClass('show alert-success alert-danger');

  $.ajax({
    type: 'POST',
    url: '/send-email',
    enctype: 'multipart/form-data',
    processData: false,
    contentType: false,
    cache: false,
    data,

    success() {
      $(form).find('.alert').removeClass('d-none').addClass('show d-block alert-success')
        .find('> span')
        .text(i18next.t('globals.demo.send.success'));
      trackEventGA(data.get('section'), 'send', 'form');
    },

    error() {
      $(form).find('.alert').removeClass('d-none').addClass('show d-block alert-danger')
        .find('> span')
        .text(i18next.t('globals.demo.send.error'));
    },

    complete() {
      $(form).find('.btn').removeAttr('disabled').text(btnText);
      $(form).trigger('reset');
      $(form).find('.custom-file-label').removeClass('selected').html(null);
    },
  });
}

function setLocale(locale) {
  i18next
    .changeLanguage(locale)
    .then(() => {
      $('body').localize();
    });
}

export default {
  goToSection,
  submitHandler,
  setLocale,
  trackEventGA,
};
